<template>
    <footer class="footer-bg text-white-1 footer-fonts font-inter-regular">
        <div class="pt-5 top-footer-cont border-bottom">
            <div class="container">
                <div class="px-lg-7">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                            <div class="">
                                <a href="" class="">
                                    <img src="../assets/img/logo/logo.png" class="img-fluid footer-logo" alt="">
                                </a>
                                <!--                                <h2 class="fs-lg-1 mb-0"></h2>-->
                                <!--                            <address class="c-address font-inter-medium mb-3"><br>-->
                                <!--                                Mattupatty P.O, Munnar, Idukki <br>-->
                                <!--                                Kerala - PIN :685 616-->
                                <!--                            </address>                            -->
                                <address class="c-address mt-3 m-0 mb-3 fs-lg--1">
                                    <p class="mb-3">Headquarters Office Address</p>
                                    <p class="mb-2">KLD Board Ltd,</p>
                                    <p class="mb-2">Head Office,</p>
                                    <p class="mb-2">Gokulam, Pattom,</p>
                                    <p class="mb-2">Thiruvananthapuram,</p>
                                    PIN :695 004
                                </address>
                            </div>
                        </div>

                        <!--                    <div class="col-12 col-md-6 col-lg-2 d-sm-none mb-4"/>-->

                        <div class="col-12 col-md-6 col-lg-3 d-sm-none mb-3 mb-lg-0">
                            <div class="fl-y fl-a-s ml-lg-5">
                                <h2 class="mb-4 font-lato-bold fs-lg-1">Quick Links</h2>
                                <ul class="list-unstyled mb-3 ul-footer">
                                    <li class="c-mb-11">
                                        <router-link to="/" class="d-block">Home</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/about-us/" class="d-block ">About Us</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/course-offered/" class="d-block ">Course Offered</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/training-centers/" class="d-block">Training Centers
                                        </router-link>

                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/contact/" class="d-block">Contact Us</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/news/" class="d-block">News</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-3 d-sm-none mb-4 mb-lg-0">
                            <div class="fl-y fl-a-s">
                                <h2 class="mb-4 font-lato-bold fs-lg-1">Training Centers</h2>
                                <ul class="list-unstyled mb-3 ul-footer">
                                    <li v-for="(i, index) in details.training_center" :key="index"
                                        class="c-mb-11">
<!--                                        <router-link v-if="index < 4" :to="'/training-centers/#item' + i.id"-->
<!--                                                     class="d-block">-->
<!--                                            {{ i.location }}-->
<!--                                        </router-link>-->
                                        <router-link :to="'/training-centers/#item' + i.id"
                                                     class="d-block">
                                            {{ i.location }}
                                        </router-link>
                                    </li>
<!--                                    <li>-->
<!--                                        <router-link :to="{path:'/training-centers/', hash: '#item12'}"-->
<!--                                                     class="d-block">-->
<!--                                            to section 2 (router-link)-->
<!--                                        </router-link>-->
<!--                                    </li>-->
                                </ul>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-3 mb-3 d-sm-none mb-lg-0">
                            <div class="fl-y fl-a-s w-100">
                                <h2 class="mb-4 font-lato-bold fs-lg-1">Contact Us</h2>
                                <ul class="list-unstyled mb-3 ul-footer">
                                    <li class="mb-2">
                                        <span class="d-block">Phone</span>
                                    </li>
<!--                                    <li class="mb-2">-->
<!--                                        <span class="d-block">+91 9675 897 365</span>-->
<!--                                    </li>-->
                                    <li class="">
                                        <span class="d-block">+91 9446004283</span>
                                    </li>
                                    <li class="mb-2 mt-41">
                                        <span>Email : </span>
                                        <span class="">kldbtcmtpty@gmail.com</span>
                                    </li>
                                    <li class="c-mb-11">
                                        <span>Fax : </span>
<!--                                        <span>info@kldb.com</span>-->
                                        <span>kldbtcmtpty@gmail.com</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-12 col-lg-3 mb-lg-0 d-lg-none d-md-none">
                            <div class="fl-y fl-a-s">

                                <base-accordion color="" heading="Quick Links">
                                    <template #default>
                                        <div class="fl-x fl-j-s ml-5">
                                            <ul class="list-unstyled mb-3 ul-footer">
                                                <li class="c-mb-11">
                                                    <router-link to="/" class="d-block ">Home</router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/about/" class="d-block ">About Us
                                                    </router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/about/" class="d-block">Course Offered
                                                    </router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/contact/" class="d-block">Training Centers
                                                    </router-link>

                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/contact/" class="d-block">Contact Us</router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/news/" class="d-block">News</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>
                                </base-accordion>
                            </div>
                        </div>

                        <div class="col-12 col-lg-3 mb-lg-0 d-lg-none d-md-none">
                            <div class="fl-y fl-a-c">
                                <base-accordion color="" heading="Training Centers">
                                    <template #default>
                                        <div class="fl-y fl-a-s ml-5">
                                            <ul class="list-unstyled mb-3 ul-footer">
                                                <li v-for="(i, index) in details.training_center" :key="index"
                                                    class="c-mb-11">
<!--                                                    <router-link v-if="index < 4" :to="'/training-centers/#item' + i.id"-->
<!--                                                                 class="d-block">-->
<!--                                                        {{ i.location }}-->
<!--                                                    </router-link> -->
                                                    <router-link :to="'/training-centers/#item' + i.id"
                                                                 class="d-block">
                                                        {{ i.location }}
                                                    </router-link>
<!--                                                    <router-link v-if="index === 4" to="/training-centers/"-->
<!--                                                                 class="d-block">-->
<!--                                                        Other locations-->
<!--                                                    </router-link>-->
                                                </li>
                                            </ul>
                                        </div>
                                    </template>
                                </base-accordion>
                            </div>
                        </div>

                        <div class="col-12 col-lg-3 mb-3 mb-lg-0 d-lg-none d-md-none">
                            <base-accordion color="" heading="Contact Us" :border-bottom="true">
                                <template #default>
                                    <div class="fl-y fl-a-s ml-5">
                                        <ul class="list-unstyled mb-3 ul-footer">
                                            <li class="c-mb-11">
                                                <span class="d-block">Phone</span>
                                            </li>
                                            <li class="c-mb-11">
                                                <span class="d-block">+91 9446004283</span>
                                            </li>
<!--                                            <li class="c-mb-11">-->
<!--                                                <span class="d-block">+91 9675 897 365</span>-->
<!--                                            </li>-->
                                            <li class="c-mb-11 mt-3">
                                                <span>Email : </span>
                                                <span>
                                                    kldbtcmtpty@gmail.com
                                                </span>
                                            </li>
                                            <li class="c-mb-11">
                                                <span>Fax : </span>
<!--                                                <span class="">info@kldb.com</span>-->
                                                <span class="">kldbtcmtpty@gmail.com</span>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </base-accordion>

                        </div>

                        <div class="col-12">
                            <div class="fl-x fl-j-c py-2">
                                <div class="social-media fl-x-cc">
                                    <a v-for="(i, index) in details.social_media" :key="index" :href="i.link">
                                    <span class="social-icon border-r-1 px-md-4 pr-lg-5 px-2">
                                        <img v-if="i.type==='Facebook'" height="40" width="40"
                                             src="../assets/web/social-media-icons/fb.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Google Plus'" height="40" width="40"
                                             src="../assets/web/social-media-icons/google-plus.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Instagram'" height="40" width="40"
                                             src="../assets/web/social-media-icons/insta.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='LinkedIn'" height="40" width="40"
                                             src="../assets/web/social-media-icons/linkedin.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Pinterest'" height="40" width="40"
                                             src="../assets/web/social-media-icons/pinterest.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='QQ'" height="40" width="40"
                                             src="../assets/web/social-media-icons/qq.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Quora'" height="40" width="40"
                                             src="../assets/web/social-media-icons/quora.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Reddit'" height="40" width="40"
                                             src="../assets/web/social-media-icons/reddit.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Sina Weibo'" height="40" width="40"
                                             src="../assets/web/social-media-icons/Sina_Weibo.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Skype'" height="40" width="40"
                                             src="../assets/web/social-media-icons/skype.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='SnapChat'" height="40" width="40"
                                             src="../assets/web/social-media-icons/Snapchat-.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Microsoft Teams'" height="40" width="40"
                                             src="../assets/web/social-media-icons/teams.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Telegram'" height="40" width="40"
                                             src="../assets/web/social-media-icons/telegram.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Twitter'" height="30" width="30"
                                             src="../assets/web/social-media-icons/twitter.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Youtube'" height="40" width="40"
                                             src="../assets/web/social-media-icons/utube.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='WeChat'" height="40" width="40"
                                             src="../assets/web/social-media-icons/Wechat.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.type==='Whatsapp'" height="40" width="40"
                                             src="../assets/web/social-media-icons/whatsapp.png" alt=""
                                             class="img-fluid"/>
                                    </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-top pos-r credit-section font-lato-regular">
                <!--                <div class="line-top"></div>-->
                <div class="container py-2">
                    <div class="px-lg-7 pos-r text-white-1">
                        <div class="row">
                            <div class="col-12">
                                <div class="c-fl-te-c">
                                    <p class="mb-lg-0 text-center fs-lg--1 text-white-2">&copy; Copyright
                                        {{ new Date().getFullYear() }}
                                        Kerala
                                        Live Stock Development Board
                                    </p>
                                    <ul class="fl-x text-decoration-none footer-credit-links">
                                        <div>
                                            <span @click="viewFile('terms_and_condition')"
                                                  class="px-2 fs-lg--1 text-white-2 cursor-pointer">Terms & Conditions</span>
                                            <span class="text-white-2 fs-lg--1">|</span>
                                        </div>
                                        <div>
                                             <span @click="viewFile('privacy_policy')"
                                                   class="px-2 fs-lg--1 text-white-2 cursor-pointer">Privacy Policy</span>
                                            <span class="text-white-2 fs-lg--1">|</span>
                                        </div>

                                        <!--                                        <router-link class="px-2 text-white-2 fs-lg&#45;&#45;1" to="/contact/">Sitemap-->
                                        <!--                                        </router-link>-->
                                        <!--                                        <router-link class="px-2" to="/Copyright">Disclaimer</router-link>-->
                                        <!--                                        <router-link class="px-2" to="/Copyright">FAQ</router-link>-->
                                    </ul>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import BaseAccordion from '@components/ui/BaseAccordion';
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    name: 'MainFooter',

    components: { BaseAccordion },

    props: {

        currentRoute: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            details: '',
            dataLoading: false
        };
    },

    beforeMount () {
        this.loadDetails();
    },

    methods: {
        viewFile (policy) {
            window.open(this.details[policy].file);
        },
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.footer);
            const json = response.data;
            if (json.error === false) {
                this.details = json;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
            this.$emit('loadingSuccess');
        }
    }
};
</script>

<style scoped lang="scss">
    .underline-item {
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -7px;
        left: 0;
        background-color: rgba(228, 233, 242, 0.6);
    }

    .social-links {
        display: flex;
        //width: 80%;
        justify-content: flex-start;
        list-style: none;

        li {
            :not(:last-child) {
                transition: all ease-in-out 300ms;
                border: 2px solid #ddd;
                opacity: 0.6;
                border-radius: 0.5rem;
                height: 45px;
                width: 45px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 1.6rem;
                    color: white;
                }

                &:hover {
                    //transform: scale(1.03);

                    i {
                        color: #9e9e9e;
                    }
                }
            }
        }
    }

    .top-footer-cont {
        //background-color: #262626;
        //color: #888888;

        .text-head {
            //font-weight: bold !important;
            //font-size: 1.2rem;
            font-family: 'inter-semi-bold', sans-serif;
            margin-bottom: var(--spacer-4);
            color: #fafafa;
        }

        .text-details {
            font-weight: bold !important;
            font-family: 'Montserrat', sans-serif;
            color: #ffffff;
        }

        .list {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                position: relative;
                padding: 10px 0;
                border-bottom: 1px solid #333333;
                transition: 0.3s;

                &:hover {
                    color: #cccccc;

                    &:after {
                        color: #cccccc;
                    }
                }

                &:after {
                    font-family: "FontAwesome";
                    content: "\f105";
                    position: absolute;
                    top: 10px;
                    right: 0;
                    font-size: 14px;
                    color: #999999;
                }
            }
        }

    }

    ul {
        a {
            text-decoration: none;
        }
    }

    a:hover {
        color: #cccccc;
    }

    .bottom-footer-cont {
        background-color: #1b1b1b;
        //color: #777777;
        color: var(--color-white);

        .bottom-footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .social-icons {
                li {
                    display: inline-block;
                    font-size: 1.4rem;

                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                }
            }
        }

        @media (min-width: 992px) {
            .bottom-footer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    .w-60 {
        width: 60% !important;
    }

    //.text-white {
    //    &:hover {
    //        opacity: 0.6 !important;
    //    }
    //}

    .ul-footer {
        li a {
            font-size: 0.9rem;
        }

        .c-mb-11 {
            margin-bottom: 0.79rem !important;
        }
    }

    .c-address {
        font-size: 0.9rem;
        font-style: normal !important;
        //line-height: 2rem;
    }

    .line-top {
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(228, 233, 242, 0.2);
    }

    .border-bottom {
        border-bottom: 1px solid rgba(228, 233, 242, 0.2);
    }

    .border-top {
        border-top: 1px solid rgba(228, 233, 242, 0.2);
    }

    .footer-logo-container {
        img {
            min-height: 3.2rem;
        }
    }

    .social-icon {
        i {
            font-size: 1.7rem;
            color: rgba(255, 255, 255, 0.7);
        }
    }

    .footer-bg {
        background: url("../assets/web/landing/footer-bgi.jpg");
        background-position: center;
        background-size: cover;
    }

    .footer-logo {
        height: 6.5rem;
    }

    .footer-credit-links {
        a {
            position: relative;
            padding: 0 var(--spacer-2);

            &:not(:last-child)::after {
                content: '';
                position: absolute;
                right: 0;
                height: 60%;
                transform: translateY(40%);
                width: 1.2px;
                background-color: rgba(255, 255, 255, 0.7) !important;
            }

        }
    }

    .footer-fonts {
        h2 {
            color: rgba(255, 255, 255, 0.9) !important;
        }
    }
</style>
